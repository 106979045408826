/*не беру старый toolbar так как там цвета прописаны*/

@import "../../../../styles/functions/_util.scss", "../../../../styles/_settings.scss";

.toolbar-top {
    position: relative;
    z-index: 101;
    line-height: 1.625;
}

.toolbar-top-item {
    padding: rem-calc(9px) 0 rem-calc(10px);
    margin: 0 rem-calc(10px);
    font-size: $font-size-normal;

    &.curency-item {
        padding: rem-calc(6px) 0;
    }
}

.toolbar-top-link, .toolbar-top-link-alt {
    position: relative;
}

.toolbar-top-link-alt {
    margin: 0 rem-calc(7px);
}

.toolbar-top-link-alt:first-child {
    margin-left: 0;
}

.toolbar-top-link-alt:last-child {
    margin-right: 0;
}


.toolbar-top-link-transformer {
    margin-left: rem-calc(15px);
}

.toolbar-top-item.curency-item {
    position: relative;
}

.toolbar-top {
    min-height: 40px /*GlorySoft_017 60px*/;


    &__personal-menu {
        position: relative;

        .personal-menu {
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.5s;
        }

        &:hover .personal-menu {
            display: block;
            opacity: 1;
            visibility: visible !important;
        }
    }

    &__zone-name {
        margin-right: 20px;
    }

    &__social {
        padding-left: 15px;
        padding-right: 15px;
        flex-grow: 1;

        .social-block__item {
            height: 24px;
        }
    }

    &-item:last-child {
        flex-grow: 1;
        justify-content: flex-end;
    }

    & > .container {
        & > .row {
            align-items: center;
            min-height: 40px /*GlorySoft_017 60px*/;
            flex-wrap: nowrap;

            .toolbar-top-item {
                font-size: 15px;
                display: flex;
                align-items: center;
                flex-shrink: 0;
                font-weight: 300;
                padding-top: 0;
                padding-bottom: 0;

                a {
                    border-bottom-style: none;
                }

                .zone-name {
                    display: none;
                }

                .zone-dialog-trigger-link {
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    font-size: 15px;
                    font-weight: 300;

                    &:before {
                        content: '';
                        -webkit-mask-image: url(../../images/city.svg);
                        mask-image: url(../../images/city.svg);
                        width: 14px;
                        height: 15px;
                        display: block;
                        margin-right: 10px;
                        background-color: currentColor;
                    }

                    &:hover {
                        span {
                            text-decoration: underline;
                        }
                    }
                }

                [data-ng-transclude] {
                    display: flex;
                    align-items: center;
                }

                .top-panel-select {
                    select {
                        font-size: 15px;
                        font-weight: 300;
                    }
                }
            }
        }
    }

    .toolbar-top-menu {
        flex-grow: 1;

        .menu-general {
            white-space: normal;
        }

        .menu-general.harmonica {
            height: 60px;
            overflow: hidden;
        }

        .menu-general.harmonica-post-calc {
            height: auto;
            overflow: initial;
        }

        .menu-general-root-link {
            font-size: 15px;
        }

        .harmonica-tile {
            padding-top: 17px;
            padding-bottom: 13px;
        }

        .harmonica-tile-inner {
            display: inline-block;
        }

        .menu-general-submenu {
            border-radius: 10px;
        }

        .menu-general-root-link {
            padding: 15px 12px;
        }

        .menu-general-sub-column {
            padding: 0;
        }

        .menu-general-category-parent > a {
            display: block;
            padding: 15px 24px;

            &:hover {
                text-decoration: none;
            }
        }

        .menu-general-item {
            &::before {
                display: none;
            }

            &:hover {
                .menu-general-root-link .menu-general-root-link-text {
                    text-decoration: underline;
                }
            }
        }

        .menu-general-item.parent {
            border-radius: 0;
            flex-shrink: 0;


            .menu-general-root-link {

                &:after {
                    content: '';
                    background: url(../../images/menu-array-gray.svg);
                    background-size: 100%;
                    width: 13px;
                    height: 10px;
                    background-repeat: no-repeat;
                    margin-left: 10px;
                    top: 1px;
                    position: relative;
                }
            }

            .menu-general-submenu.show-submenu ~ .menu-general-root-link {
                &:after {
                    transform: rotate(180deg);
                    position: relative;
                    top: -1px;
                }
            }
        }
    }
}


.toolbar-top-item-phone {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}